// LEARNING_JOURNEY.SCSS

.learning_journey {
  margin-bottom: 60px;
  background-color: $white;
  height: 100%;

  .top-nav-fixed {
    position: fixed;
    width: 100%;
    z-index: 3;
  }

  .side-nav-fixed {
    margin-top: 110px;
    // position: fixed;
    // max-width: 256px;

    // @media screen and (max-width: 1199px) {
    //   max-width: 210px;
    // }
    // @media screen and (max-width: 991px) {
    //   position: relative;
    //   max-width: 100%;
    //   margin-top: 120px;
    // }
    @media screen and (max-width: 767px) {
      margin-top: 300px;
    }
  }
  .content-scroll {
    margin-top: 86px;
    @media screen and (max-width: 991px) {
      margin-top: -40px;
    }
  }
  .path-content-scroll {
    margin-top: 86px;
    @media screen and (max-width: 991px) {
      margin-top: 0px;
    }
  }

  .lj-arrow-bg {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .lj-arrow {
    display: none;
    position: relative;
    max-width: 1400px;
    margin: 0 auto;

    .lj-arrow-row {
      background-color: $white; // $aon-blue;
      height: 50px;
      margin: 185px 150px;

      &:first-child {
        &:before {
          content: "";
          box-sizing: border-box;
          position: absolute;
          width: 190px;
          height: 50px;
          background: $white; // $aon-blue;
          margin-left: -164px;
          margin-top: 0px;
          border-radius: 30px;
        }
      }

      &.left:not(:first-child):last-child {
        &:before {
          content: "";
          box-sizing: border-box;
          position: absolute;
          width: 190px;
          height: 50px;
          background: $white; // $aon-blue;
          margin-left: -120px;
          margin-top: 0px;
        }
      }
      &.right:not(:first-child):last-child {
        &:before {
          content: "";
          box-sizing: border-box;
          position: absolute;
          right: 0px;
          width: 190px;
          height: 50px;
          background: $white; // $aon-blue;
          margin-right: 30px;
          margin-top: 0px;
        }
      }
      &.right:only-child {
        &:after {
          content: "";
          box-sizing: border-box;
          position: absolute;
          right: 0px;
          width: 190px;
          height: 50px;
          background: $white; // $aon-blue;
          margin-right: 30px;
          margin-top: 0px;
        }
      }
    }

    .lj-arrow-curve {
      background-color: transparent;
      position: absolute;
      height: 200px;
      width: 234px;
      border-top: 0px;

      &.right {
        right: 10px;
        transform: rotate(-90deg);
        margin-top: 43px;

        &:before {
          content: "";
          box-sizing: border-box;
          position: absolute;
          border: 50px solid $white; // $aon-blue;
          width: 285px;
          height: 240px;
          right: -50px;
          transform: rotate(180deg);
          border-top-left-radius: 9em;
          border-top-right-radius: 9em;
          border-bottom: none;
        }
      }

      &.left {
        left: 10px;
        transform: rotate(90deg);
        margin-top: -6px;

        &:before {
          content: "";
          box-sizing: border-box;
          position: absolute;
          border: 50px solid $white; // $aon-blue;
          width: 285px;
          height: 240px;
          right: -50px;
          transform: rotate(180deg);
          border-top-left-radius: 9em;
          border-top-right-radius: 9em;
          border-bottom: none;
        }
      }
    }

    .lj-arrow-point {
      background-color: $white; // $aon-blue;
    }
    .lj-arrow-point:before,
    .lj-arrow-point:after {
      content: "";
      position: absolute;
      background-color: inherit;
    }
    .lj-arrow-point,
    .lj-arrow-point:before,
    .lj-arrow-point:after {
      width: 40px;
      height: 40px;
      border-top-right-radius: 30%;
    }

    .lj-arrow-point.left {
      margin-top: -30px;
      position: absolute;
      left: 12px;
      transform: rotate(-30deg) skewX(-30deg) scale(1, 0.866);
    }
    .lj-arrow-point.right {
      margin-top: -30px;
      position: absolute;
      right: 12px;
      transform: rotate(-90deg) skewX(-30deg) scale(1, 0.866);
    }
    .lj-arrow-point:before {
      transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
        translate(0, -50%);
    }
    .lj-arrow-point:after {
      transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
    }
  }

  .journey-cards {
    background-color: $white;
    h2 {
      font-family: "Roboto Slab Light", Georgia, serif;
    }

    .card-deck-wrapper {
      position: relative;
      margin-top: -30px;
    }

    .card-deck {
      padding: 16px 80px;
    }

    .card {
      box-shadow: none;
      border: none;
      background-color: $grey-bg;
      max-width: 222px;

      &.in-progress {
        background-color: $aon-yellow;
        .footer-icon {
          color: lighten($aon-yellow, 50%);
          opacity: 0.3;
        }
      }

      &.completed,
      &.Complete {
        background-color: $aon-bluegreen;
        .footer-icon {
          color: lighten($aon-bluegreen, 50%);
          opacity: 0.3;
        }
      }

      &.check-in {
        .footer-icon {
          color: darken($grey-bg, 50%);
          opacity: 0.3;
        }
      }

      .card-header {
        background-color: inherit;
        border: none;
        font-size: 13px;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        max-height: 30px;
        // margin: 0 auto;
        line-height: 16px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .card-body {
        padding: 16px 20px 30px 20px;

        h5 {
          font-family: "Roboto Slab Light", Georgia, serif;
          font-size: 22px;
          margin: 0px;
          display: block;
          display: -webkit-box;
          max-width: 100%;
          max-height: 54px;
          margin: 0 auto;
          line-height: 26px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          a {
            color: $grey-text;
            text-decoration: none;
            word-break: normal;

            &:hover {
              color: lighten($grey-text, 20%);
            }
          }
        }
      }

      .card-footer {
        background-color: inherit;
        border-top: none;
        padding-top: 0px;

        .footer-text {
          font-size: 12px;
          display: block;

          .footer-date {
            .status-badge:before {
              display: none;
            }
          }
        }

        .btn-explore {
          margin-top: 10px;
        }

        .footer-icon {
          display: inline-block;
          float: right;
          width: 40px;
          height: 40px;
          position: absolute;
          right: 20px;
          bottom: 12px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  nav.top-nav {
    background: $white;
    width: 100%;
    display: table;
    table-layout: fixed;
    padding: 16px;

    ul {
      margin: 0px;
      padding: 0px;
      display: flex;
      flex-direction: row;

      li {
        flex-grow: 1;
        height: 66px;
        background-color: $grey-bg;

        &.list-group-item {
          border: none;
          font-size: 14px;
          font-family: "Roboto Slab", Georgia, serif;
          border-right: 1px solid darken($grey-bg, 5%);
          flex: 1 1 auto;
          width: 100%;

          &:first-child {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 0.25rem;
          }
          &:last-child {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0px;
            border-right: none;
          }
          &:hover {
            background-color: darken($grey-bg, 2%);
          }
          &.active {
            background: $aon-blue;

            outline: none;
            font-weight: bold;
            border: none;
            .top-nav-icon {
              color: lighten($aon-blue, 15%);
              opacity: 1;
            }
          }
        }

        .top-nav-icon {
          display: inline-block;
          float: right;
          width: 20px;
          height: 20px;
          position: absolute;
          right: 6px;
          bottom: 12px;
          color: darken($grey-bg, 50%);
          opacity: 0.3;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        a {
          display: block;
          text-decoration: none;
          color: $grey-text;
          word-break: break-word;
          margin-right: 10px;
        }
      }
    }
  }

  .journey-container {
    background-color: $white;

    #sectionName {
      font-size: 20px;
      font-family: "Roboto Slab Regular", Georgia, serif;
    }

    .side-nav {
      .list-group-item {
        border-left: 1px solid #00000020;
        border-right: 1px solid #00000020;
        color: $grey-text;
        font-size: 14px;
        padding: 10px 16px;
        word-break: keep-all;

        &:not(:first-child) {
          border-top: none;
        }

        &:not(:last-child) {
          border-bottom: none;
        }

        &:hover {
          background-color: lighten($grey-bg, 2%);
        }

        a {
          color: $grey-text;
          text-decoration: none;
        }

        .side-nav-icon {
          float: right;
          color: $grey-mid-light;
          opacity: 1;
        }

        &.active {
          background: $grey-bg;
          border-color: #0000000f;

          outline: none;
          font-weight: bold;
        }
        &.in-progress {
          color: $grey-text;
          a {
            color: $grey-text;
          }
          .side-nav-icon {
            color: $aon-yellow;
          }
        }
        &.completed,
        &.Complete {
          color: $grey-text;
          a {
            color: $grey-text;
          }

          .side-nav-icon {
            color: $aon-bluegreen !important;
          }
        }

        &.side-nav-list {
          padding: 10px 16px;

          .side-nav-list-item {
            margin-left: 16px;
            word-break: keep-all;
            font-size: 12px;
          }
        }
      }
    }

    .journey-content {
      h1 {
        font-family: "Roboto Slab Regular", Georgia, serif;
        font-size: 28px;
      }

      .description {
        // font-weight: 300;
        font-family: "Roboto Regular", Helvetica, sans-serif;
        font-size: 14px;
      }

      .path-img {
        width: auto;
        height: 65px;
      }

      .path-group {
        // margin-left: 82px;

        .path-group-section {
          background-color: $grey-bg;
          padding: 10px;
          border-radius: 4px;

          h4 {
            margin-bottom: 4px;
          }

          .btn-show {
            width: 90px;
            font-size: 14px;
          }
          .section-title {
            font-family: "Roboto Slab Regular", Georgia, serif;
            color: $grey-text;
            font-size: 22px;
            margin-top: 2px;
          }

          .section-description {
            margin: 12px 0px 6px 0px;
            // font-weight: 300;
            font-family: "Roboto Regular", Helvetica, sans-serif;
            font-size: 14px;
          }
        }

        .list-group.journey {
          margin-left: 20px;
          margin-top: 10px;

          .list-group-item {
            padding: 12px 0px;
            margin: 0px;
            border: none;
            border-bottom: 1px solid $grey-bg;
            &:hover {
              border: none !important;
              border-bottom: 1px solid $grey-bg !important;
            }
            &:last-child {
              border-bottom: none;
            }

            img {
              width: 45px;
              height: 45px;
            }

            h3 {
              font-family: "Roboto Slab Regular", Georgia, serif;
              font-size: 18px;

              a {
                color: $aon-teal;
                word-break: normal;

                &:hover {
                  color: lighten($aon-teal, 10%);
                  text-decoration: none;
                }
              }
            }

            &:hover,
            &:active {
              border: none !important;
              background-color: $white;
            }

            .lxp-self-progress-wrapper {
              height: 60px;
              margin-top: -18px;

              &.self-status {
                margin-right: 16px;
              }
            }

            .content-details {
              font-size: 10px;
              font-weight: bold;
              text-transform: uppercase;
              color: $grey-mid-light;
              // margin-bottom: 6px;
            }

            p {
              margin-bottom: 0px;
              line-height: 20px;
              word-break: normal;
            }

            .btn-explore,
            .btn-launch,
            .btn-show,
            .btn-add {
              width: 130px;
            }
            .btn-add {
              width: 160px;
            }
          }
        }
      }
    }

    .journey-status {
      background-color: $grey-bg;
      border-radius: 4px;
      padding: 10px 20px 0px 20px;

      .lxp-self-progress-wrapper {
        margin: 0px;
        padding: 0px;
        width: 100%;

        .status-label {
          font-size: 20px;
          font-family: "Roboto Slab Light", Georgia, serif;
          margin-right: 10px;
        }

        .form-control {
          margin-top: 10px;
          background-color: $white;
          width: 100%;
        }

        .no-self-progress {
          width: 100%;
          margin: 0px 0px 20px 0px;
        }
      }
    }

    .journey-notes {
      background-color: lighten($aon-blue, 30%);
      border: $aon-blue;
      border-radius: 0.25rem;
      margin-top: 120px;

      @media screen and (max-width: 991px) {
        margin-top: -60px;
        margin-bottom: 60px !important;
      }

      h2 {
        font-family: "Roboto Slab Light", Georgia, serif;
        font-size: 20px;
        margin: 0.67em 0 8px 0;
      }
      p {
        line-height: 1rem;
      }

      &.notes-recorded {
        background-color: $grey-bg;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .learning_journey {
    .journey-cards {
      .card-deck {
        padding: 16px 70px;
      }
      .card {
        .card-body {
          h5 {
            font-size: 20px;
          }
        }
        .card-footer {
          .footer-text {
            font-size: 11px;
          }
          .footer-icon {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    nav.top-nav {
      ul {
        li {
          &.list-group-item {
            font-size: 14px;
            padding: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .learning_journey {
    .journey-cards {
      .card-deck {
        padding: 24px 60px;
      }
      .card {
        .card-header {
          -webkit-line-clamp: 2;
          max-height: 60px;
        }
        .card-body {
          padding: 10px 20px 16px 20px;
          h5 {
            font-size: 14px;
            line-height: 22px;
          }
        }
        .card-footer {
          .btn-explore {
            font-size: 15px;
          }
          .footer-text {
            .footer-date {
              display: none;
            }
          }
          .footer-icon {
            display: none;
          }
        }
      }
    }

    nav.top-nav {
      ul {
        li {
          &.list-group-item {
            font-size: 12px;
            padding: 8px;
          }
        }
      }
    }
  }

  .btn-launch {
    width: 170px !important;
  }
}

@media screen and (max-width: 767px) {
  .learning_journey {
    .lj-arrow {
      display: none !important;
    }

    .journey-cards {
      padding: 20px;
      .card-deck-wrapper {
        margin-right: 0;
        margin-left: 0;

        .card-deck {
          display: block;
          width: 100%;
          table-layout: fixed;
          border-spacing: 1.25rem 0;
          margin: 0px;
          padding: 0px 40px;

          .card {
            display: block;
            margin: 20px 0px;
            vertical-align: top;
            .card-body {
              h5 {
                font-size: 24px;
                line-height: 30px;
                max-height: 64px;
              }
            }
            .card-footer {
              .btn-explore {
                font-size: 16px;
              }
              .footer-text {
                .footer-date {
                  display: inline;
                }
              }
              .footer-icon {
                display: inline;
              }
            }
          }
        }
      }
    }

    nav.top-nav {
      ul {
        display: block !important;
        border-bottom: none;
        li {
          display: block;
          width: 100%;
          height: auto;

          &.list-group-item {
            border-right: none;
            font-size: 15px;
            padding: 0.75rem 1.25rem;

            &:first-child {
              border-bottom-left-radius: 0px;
              border-top-right-radius: 0.25rem;
            }
            &:last-child {
              border-bottom-left-radius: 0.25rem;
              border-top-right-radius: 0px;
            }
          }
          .top-nav-icon {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .btn-launch {
    width: 116px !important;
    word-break: break-word;
  }
}
