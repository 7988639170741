// PROGRESS.SCSS

.progress-banner {
  //background-image: url("/static/img/demo/banner-progress-space.jpg");
  background-color: $grey-bg;
  background-size: cover;

  .prompt {
    background-color: rgba($aon-purple, 0.7);
    padding: 40px 20px;
  }

  h1 {
    color: $white;
    font-family: "Roboto Slab Thin", Georgia, serif;
    margin: 0px;
    font-size: 28px;
  }

  .description {
    color: $white;
    font-size: 15px;
    font-family: "Roboto Light", Helvetica, sans-serif;
    margin-top: 12px;
  }
}

.my-progress {
  padding: 0px;

  @media screen and (max-width: 575px) {
    padding: 0px 15px;
  }

  .my-snapshot,
  .manager-snapshot {
    background-color: $white;
    padding: 20px;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

    h2 {
      font-family: "Roboto Slab Regular", Georgia, serif;
      font-size: 28px;
    }

    .description {
      // font-weight: 300;
      line-height: inherit;
      font-family: "Roboto Regular", Helvetica, sans-serif;
      font-size: 14px;
    }

    // .required-learning,
    // .learning-paths,
    // .my-playlist,
    // .my-cpds {
    //   text-align: center;
    //   padding-top: 20px;
    //
    //   h3 {
    //     font-family: 'Roboto Slab Light', Georgia, serif;
    //     font-size: 24px;
    //   }
    //
    //   h4 {
    //     font-family: 'Roboto Bold', Helvetica, sans-serif;
    //     font-size: 12px;
    //   }
    // }

    .progress-graph {
      position: relative;
      margin: 20px;
      text-align: center;
      font-family: "Roboto Slab Bold", Georgia, serif;
      font-size: 24px;
    }
    .progress-overflow {
      position: relative;
      overflow: hidden;
      width: 250px;
      height: 125px;
      margin: 0 auto -36px auto;
    }
    .progress-percent {
      position: absolute;
      top: 0;
      left: 0;
      width: 250px;
      height: 250px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 40px solid $grey-bg;
      border-bottom-color: $aon-blue;
      border-right-color: $aon-blue;
    }

    .table {
      font-size: 14px;

      .thead-light th {
        color: $grey-text;
        background-color: $grey-bg;
        border-color: $grey-bg;
        vertical-align: middle;
        font-weight: 600;
      }

      &.table-hover {
        tbody tr:hover td {
          background-color: lighten($aon-blue, 30%);
        }
      }

      td {
        vertical-align: middle;
        border-top: 1px solid $grey-bg;
      }

      &.manager-snapshot-table {
        thead tr.thead-primary th {
          padding: 2px 6px;
          font-size: 12px;
          line-height: 16px;
          border: 1px solid $grey-light;
        }

        thead tr.thead-secondary th {
          padding: 2px 6px;
          font-size: 12px;
          line-height: 16px;
          border: 1px solid transparent;

          &:first-child {
            border-left: 1px solid $grey-light;
            border-bottom: 1px solid $grey-light;
          }
          &:nth-child(2) {
            border-bottom: 1px solid $grey-light;
            border-right: 1px solid $grey-light;
          }
          &:nth-child(3) {
            border-bottom: 1px solid $grey-light;
            border-right: 1px solid $grey-light;
          }
          &:nth-child(4) {
            border-bottom: 1px solid $grey-light;
            border-right: 1px solid $grey-light;
          }
          &:nth-child(5) {
            border-bottom: 1px solid $grey-light;
            border-right: 1px solid $grey-light;
          }
          &:nth-child(6) {
            border-bottom: 1px solid $grey-light;
            border-right: 1px solid $grey-light;
          }
          &:nth-child(7) {
            border-bottom: 1px solid $grey-light;
          }
          &:nth-child(8) {
            border-bottom: 1px solid $grey-light;
          }
          &:last-child {
            border-right: 1px solid $grey-light;
            border-bottom: 1px solid $grey-light;
          }
        }

        tbody tr td {
          padding: 12px 6px;
          border: 1px solid transparent;
        }
      }
    }

    .load-plan {
      // min-width: 125px;
      word-break: normal;
      color: white;

      &:hover {
        color: white;
      }
    }

    .progress-snapshot {
      background-color: $grey-bg;
      padding: 10px 10px 20px 10px;
      text-align: center;
      font-size: 18px;
      font-family: "Roboto Slab Light", Georgia, serif;
      position: relative;
      min-height: 216px;

      @media screen and (max-width: 1199px) {
        min-height: 236px;
      }
      @media screen and (max-width: 991px) {
        min-height: 60px;
      }

      .progress-snapshot-num {
        font-size: 60px;
        margin-top: -10px;
        position: relative;
      }

      .progress-snapshot-title {
        position: relative;

        .text-14 {
          font-size: 14px;
        }

        small {
          font-size: 12px;
        }
      }

      .progress-snapshot-ly {
        padding: 10px;
        font-size: 16px;
        color: $aon-blue;
        position: absolute;
        bottom: 0px;
        width: calc(100% - 20px);

        &.smaller-text {
          font-size: 14px;
          padding: 10px 4px;
          line-height: 18px;
        }

        @media screen and (max-width: 991px) {
          position: relative;
          width: 100%;
          padding-bottom: 0px;
        }

        a {
          color: $aon-teal;
          word-break: break-word;
        }
      }
    }

    .learning-plan-summary {
      background-color: $white;

      .container {
        .btn.view {
          font-family: "Roboto Slab Light", Georgia, serif;
        }

        h2 {
          font-family: "Roboto Slab Regular", Georgia, serif;
          font-size: 24px;
        }

        .card {
          border: none;

          h5,
          h5 a {
            color: $grey-text;
            font-family: "Roboto Slab Light", Georgia, serif;

            .badge.badge-blue {
              font-family: "Roboto Light", Helvetica, sans-serif;
              background-color: $aon-blue;
              color: $white;
            }
          }

          h5 a {
            &:hover {
              color: lighten($grey-text, 20%);
              text-decoration: none;
            }
          }

          .list-group {
            img {
              height: 50px;
              width: 50px;
            }

            .list-group-item {
              padding: 10px 0px;
              border: none;

              &:hover,
              &:active {
                bborder: none;
                background-color: $white;
              }

              p {
                margin-bottom: 0px;
                line-height: 16px;
                word-break: normal;
              }
            }
          }

          .card-empty {
            text-align: center;

            img {
              width: 50%;
              margin: 10px 10px 16px 10px;
            }

            p {
              font-family: "Roboto Bold", Helvetica, sans-serif;
              font-size: 13px;
              color: $grey-mid-light;
              margin: 0px;
            }
          }
        }
      }
    }
  }

  .progress-graphs {
    .card {
      background-color: $white;
      padding: 20px;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

      h2 {
        font-family: "Roboto Slab Regular", Georgia, serif;
        font-size: 24px;

        @media screen and (max-width: 991px) {
          font-size: 18px;
        }
      }

      .progress-graph {
        .progress-graph-label {
          font-size: 12px;
          font-weight: 500;
          width: 75px;
          margin-top: 4px;
          text-align: right;
          padding-right: 6px;
          @media screen and (max-width: 991px) {
            text-align: left;
          }
        }

        .progress-graph-holder {
          width: 82%;

          .progress {
            height: 24px;
            background-color: $grey-bg;

            .not-started {
              background-color: $grey-mid-light;
            }
            .in-progress {
              background-color: $aon-blue;
            }
            .completed {
              background-color: lighten($aon-purple, 20%);
            }
          }
        }
      }

      &.lp-sum {
        .progress-graph {
          .progress-graph-label {
            width: 100px;
          }
          .progress-graph-holder {
            width: 80%;
            @media screen and (max-width: 1199px) {
              width: 76%;
            }
            @media screen and (max-width: 991px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// Preview Progress
.preview-progress {
  background-color: $aon-red;

  .alert {
    color: $white;
    margin-bottom: 0px;
    font-family: "Roboto Slab Light", Georgia, serif;
    text-align: center;

    .close:hover {
      color: $white;
    }
  }
}

@media screen and (max-width: 1199px) {
  .my-progress {
    .my-snapshot {
      .progress-snapshot {
        font-size: 17px;

        .progress-snapshot-num {
          font-size: 60px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  // .my-progress {
  //   .my-snapshot {
  //     .progress-snapshot {
  //     margin-bottom: 0px;
  //     }

  //     .col-lg:last-child .progress-snapshot {
  //       margin-bottom: 0px;
  //     }
  //   }
  // }

  .my-progress {
    .progress-graphs {
      .card {
        .progress-graph {
          .progress-graph-holder {
            width: 100%;
          }
        }
      }
    }
  }
}
