// LEARNING_PLAN.SCSS

.container.learning_plan:before {
  margin-top: -150px;
  height: 150px;
  content: "";
  display: block;
}

.learning_plan-banner {
  // background-image: url('/static/img/demo/banner-learningPaths-lightBulbs.jpg');
  background-color: $sage-green;
  background-size: cover;

  .prompt {
    // background-color: rgba($aon-blue-dark, 0.7);
    padding: 30px 20px;

    @media screen and (max-width: 991px) {
      display: block !important;
    }
  }

  h1 {
    color: $white;
    font-family: "Roboto Slab Thin", Georgia, serif;
    margin: 0px;
    font-size: 28px;
  }

  .description {
    color: $white;
    font-size: 15px;
    font-family: "Roboto Light", Helvetica, sans-serif;
    margin: 0px;

    @media screen and (max-width: 991px) {
      margin-top: 12px;
    }
  }
}

.learning_plan {
  padding: 0px;

  .required_learning,
  .learning_paths,
  .playlist {
    background-color: $white;
    padding: 20px;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

    h2 {
      font-family: "Roboto Slab Regular", Georgia, serif;
      font-size: 28px;
    }

    p {
      margin-bottom: 0px;
      line-height: inherit;
      word-break: normal;
      // font-weight: 300;
      font-family: "Roboto Regular", Helvetica, sans-serif;
      font-size: 14px;

      &.description {
        // max-width: 660px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }

    .list-group {
      // border-bottom: 1px solid $grey-light;
      padding: 10px 0px;

      .list-group-item {
        padding: 10px 0px;
        border: none;

        &:hover,
        &:active {
          bborder: none;
          background-color: $white;
        }

        img {
          height: 80px;
          width: 80px;
          min-width: 80px;
          object-fit: cover;
        }

        h3 {
          font-family: "Roboto Slab Regular", Georgia, serif;
          font-size: 24px;

          a {
            color: $grey-text;
            word-break: normal;

            &:hover {
              color: lighten($grey-text, 20%);
              text-decoration: none;
            }
          }
        }

        p {
          margin-bottom: 0px;
          line-height: inherit;
          word-break: normal;
          // font-weight: 300;
          font-family: "Roboto Regular", Helvetica, sans-serif;
          font-size: 14px;

          &.description {
            // max-width: 660px;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
          }
        }

        .description {
          font-family: "Roboto Regular", Helvetica, sans-serif;
          font-size: 14px;

          &.truncate {
            display: block;
            display: -webkit-box;
            max-width: 100%;
            max-height: 44px;
            margin: 0 auto;
            line-height: inherit;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            // font-weight: 300;
          }
        }

        // div.description {
        //   max-width: 660px;
        //   white-space: nowrap;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        // }

        .content-details {
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          color: $grey-mid-light;
          margin-bottom: 6px;
        }

        .quick-links {
          margin-top: 10px;
          display: inline-flex;
          align-items: center;

          @media screen and (max-width: 991px) {
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            padding: 0px 0px 0px 94px;
          }
          @media screen and (max-width: 767px) {
            position: relative;
            padding: 0px 0px 0px 0px;
            display: block;
          }

          a,
          .add-to-playlist,
          .add-to-plan {
            font-size: 12px;
            color: $grey-text;
            background-color: $grey-light;
            padding: 4px 6px;
            border-radius: 4px;

            &:hover {
              text-decoration: none;
              color: lighten($grey-text, 20%);
              cursor: pointer;
            }
          }
        }

        .btn-launch,
        .btn-show,
        .btn-review,
        .btn-resume {
          width: 126px;
          height: 30px;
        }

        .btn-cog {
          margin-right: 20px;
        }

        .course-container {
          max-width: 650px;
        }

        .lxp-self-progress-wrapper {
          margin-top: -24px;
        }
      }
    }

    .custom-switch .custom-control-label::before {
      background-color: $grey-text;
      cursor: pointer;
    }

    .custom-switch .custom-control-label::after {
      background-color: $white;
      cursor: pointer;
    }

    .collapse,
    .collapsing {
      margin: 0px 20px 0px 116px;

      @media screen and (max-width: 991px) {
        margin: 20px 20px 0px 96px;
      }

      label .self-check {
        color: $aon-blue;
        font-family: "Roboto Slab Regular", Georgia, serif;
        font-size: 22px;
      }

      label .post-check {
        color: $aon-yellow;
        font-family: "Roboto Slab Regular", Georgia, serif;
        font-size: 22px;
      }

      .section-title {
        font-family: "Roboto Slab Regular", Georgia, serif;
        color: $grey-text;
        font-size: 22px;
        margin-top: 20px;
      }

      .list-group.course {
        .list-group-item {
          img {
            width: 80px;
            height: 80px;
            min-width: 80px;
            object-fit: cover;
          }

          h5 {
            font-family: "Roboto Slab Regular", Georgia, serif;
            font-size: 18px;

            a {
              color: $aon-teal;
              word-break: normal;

              &:hover {
                color: lighten($aon-teal, 10%);
                text-decoration: none;
              }
            }
          }

          .quick-links {
            a {
              color: $grey-text;

              &:hover {
                color: lighten($grey-text, 20%);
              }
            }
          }
        }
      }
    }
  }

  .required_learning {
    .list-group {
      border: 1px solid transparent;
      padding: 20px 0px;
      border-bottom: 1px solid $grey-light;

      &:last-child {
        border-bottom: 1px solid transparent;
      }

      @media screen and (max-width: 991px) {
        padding: 20px 0px 40px 0px;
      }
    }

    // .list-group {
    //   &:nth-child(2) {
    //     border: none;
    //     padding-top: 0px;
    //   }
    // }

    hr:last-child {
      display: none;
    }
  }

  .learning_paths {
    .list-group {
      border: 1px solid transparent;
      padding: 20px 0px;
      border-bottom: 1px solid $grey-light;

      @media screen and (max-width: 991px) {
        padding: 20px 0px 40px 0px;
      }

      &:last-child {
        border-bottom: 1px solid transparent;
      }
    }

    // .list-group {
    //   &:nth-child(2) {
    //     border: none;
    //     padding-top: 0px;
    //   }
    // }

    // .paths {
    //   .list-group {
    //     &:nth-child(2) {
    //       border: none;
    //       padding-top: 0px;
    //     }
    //   }
    // }

    hr:last-child {
      display: none;
    }
  }

  .playlist {
    .list-group {
      border: 1px solid transparent;
      padding: 20px 0px;
      border-bottom: 1px solid $grey-light;

      @media screen and (max-width: 991px) {
        padding: 20px 0px 40px 0px;
      }

      &:last-child {
        border-bottom: 1px solid transparent;
      }
    }

    hr:last-child {
      display: none;
    }
  }
}

@media screen and (max-width: 1199px) {
  .learning_plan {
    .required_learning,
    .learning_paths,
    .playlist {
      .list-group {
        .list-group-item {
          p {
            &.description {
              max-width: 470px;
            }
          }
          .progress-pill {
            width: 160px;
          }

          .btn-launch,
          .btn-show,
          .btn-review,
          .btn-resume {
            width: 146px;
          }

          .course-container {
            max-width: 484px;
          }
        }
      }
      .collapse,
      .collapsing {
        width: 806px;
        float: right;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .learning_plan {
    .required_learning,
    .learning_paths,
    .playlist {
      .list-group {
        .list-group-item {
          .quick-links a,
          .quick-links .add-to-plan,
          .quick-links .add-to-playlist,
          .quick-links input.goal-date {
            display: inline-block;
          }

          p {
            &.description {
              max-width: 240px;
            }
          }
          .progress-pill {
            width: 200px;
          }

          .btn-launch,
          .btn-show,
          .btn-review,
          .btn-resume {
            width: 190px;
          }

          .course-container {
            // max-width: 240px;
          }
        }
      }
      .collapse,
      .collapsing {
        width: 566px;
        float: right;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .learning_plan {
    .required_learning,
    .learning_paths,
    .playlist {
      .list-group {
        .list-group-item {
          .quick-links a,
          .quick-links .add-to-plan,
          .quick-links .add-to-playlist,
          .quick-links input.goal-date {
            display: inline-block;
            width: 100%;
            max-width: 200px;
            text-align: center;
            margin-top: 3px;
          }

          img {
            float: left;
          }

          .w-100 {
            padding-left: 116px;
          }

          .progress-pill {
            margin: 20px auto 0 auto;
          }

          .btn-launch,
          .btn-show,
          .btn-review,
          .btn-resume {
            width: 100%;
            margin-top: 20px;
          }

          .btn-cog {
            display: block;
            margin: 20px auto 0 auto;
            border: 1px solid $grey-light;
          }
        }
      }
      .collapse,
      .collapsing {
        width: 100%;
        float: none;
        margin: 0px;
      }
    }
  }
}
