.simulation {
  padding-bottom: 3rem;

  .welcome,
  .quiz,
  .feedback {
    background-color: $white;
  }
  .steps {
    img {
      height: 90px;
    }
  }

  &.photoBg {
    height: 100%;
    padding: 8rem;

    color: $white;
    text-align: center;

    background-repeat: no-repeat;
    background-size: cover; /* contain */
    background-position: center 25%;
    //background-image: url('/static/img/simulation/bg-simulation.jpg');
    box-shadow: inset 0 0 0 1000px rgba($aon-red, 0.80);

    @media screen and (max-width: 575px) {
      padding: 4rem;
    }
  }

  .card {
    border-radius: 0;
  }
  .next-steps {
    border: 0;
    background: lighten($aon-blue,30%);

    a:not(.btn) {
      color: $aon-teal;

      &:hover {
        color: lighten($aon-teal, 10%);
        text-decoration: none;
      }
    }
  }
  .conversations {
    border: 0;
    color: $white;
    background: $aon-teal;
  }

  .bg-success {
    background-color: $simBest !important;
  }
  .bg-warning {
    background-color: $simGood !important;
  }
  .bg-danger {
    background-color: $simPoor !important;
  }
  
  .text-success {
    color: $simBest !important;
  }
  .text-warning {
    color: darken($simGood, 7%) !important;
  }
  .text-danger {
    color: $simPoor !important;
  }
  .text-brand {
    color: $aon-red !important;
  }
  .text-teal {
    color: $aon-teal !important;
  }

  a {
    word-break: normal;

    .character-response & {
      color: $aon-teal;

      &:hover {
        color: lighten($aon-teal, 10%);
        text-decoration: none;
      }
    }
  }
  .btn-teal {
    &:visited {
      background-color: $aon-teal !important;

      &:hover {
        background-color: lighten($aon-teal, 5%) !important;
        text-decoration: none;
      }
    }
  }
  .btn-bordered {
    font-size: 2rem;
    color: $white;
    padding-right: 1rem;
    padding-left: 1rem;
    border: 1px solid $white;
    background-color: none;

    &:hover {
      color: $white;
      background-color: darken($aon-red, 5%);
    }
  }
  .btn-white {
    color: $aon-teal;
    border: 1px solid $white;
    background-color: $white;

    &:hover {
      color: $white;
      border: 1px solid lighten($aon-teal, 10%);
      background-color: lighten($aon-teal, 10%);
    }
  }
}

.quiz {
  .overlay-video {
    position: absolute;
    z-index: 2;
    top: 0%;
    left: 15px;
    right: 15px;
    bottom: 0%;
  }
  .overlayHeight {
    background-color: rgba($grey-text, 0.75);

    @media screen and (max-width: 767px) {
      .question-title {
        font-size: 1.5rem;
      }
    }
  }

  .card {
    cursor: pointer;
    // word-wrap: normal;
    word-break: normal;
    border: 0;
    border-top: 5px solid $aon-red;
    color: $grey-text !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    &.border {
      border: 1px solid $grey-light !important;
      border-top: 5px solid $aon-red !important;
    }

    &:hover {
      background: $aon-red;
      color: $white !important;
      text-decoration: none;
    }

    @media screen and (max-width: 767px) {
      &.card-body {
        padding: 1rem;
        font-size: 75%;
      }
    }
  }

  .speech-bubble {
  	position: relative;
    margin-top: 5px;
    padding: 2rem;
    color: $grey-dark;
  	background: lighten($aon-blue,30%);
    border-radius: .25em;
    
    h4 {
      line-height: 1.4;
      margin-bottom: 0px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0px;
      padding: 1.5rem;

      h4 {
        font-size: 1rem;
      }
      .btn {
        // mimics btn-sm without classname
        padding: .25rem .5rem;
        font-size: .875rem;
        line-height: 1.5;
        border-radius: .2rem;
      }
    }

    &:after {
      content: '';
    	position: absolute;
    	top: 0;
    	left: 20%;
    	width: 0;
    	height: 0;
    	border: 20px solid transparent;
    	border-bottom-color: lighten($aon-blue,30%);
    	border-top: 0;
    	margin-left: -20px;
    	margin-top: -20px;

      // @media screen and (min-width: 992px) {
      //   left: 0;
      // 	top: 20%;
      // 	border-right-color: lighten($aon-blue,30%);
      // 	border-left: 0;
      //   margin-top: -20px;
      //   margin-left: -20px;
      // }
      // @media screen and (max-width: 991px) {
      //   bottom: 0;
      // 	left: 20%;
      // 	border-top-color: lighten($aon-blue,30%);
      // 	border-bottom: 0;
      //   margin-left: -20px;
	    //   margin-bottom: -20px;
      // }
    }
  }

  .sb-text-bubble {
    .speech-bubble {
      &:after {
        content: '';
        position: absolute;
        top: 60px;
        left: -10px;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-bottom-color: lighten($aon-blue,30%);
        border-top: 0;
        margin-left: -20px;
        margin-top: -20px;
        transform: rotate(-90deg);
      }
    }
  }
  .text-choices {
    .text-card:last-child {
      form {
        margin-right: 0px !important;
      }
    }
    .text-card {
      form {
        @media screen and (max-width: 767px) {
         margin-right: 0px !important; 
         margin-bottom: 10px;
        }
      }
    }
  }
}
