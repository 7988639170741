// RESOURCES-DSI.SCSS

.lt-lightblue-background {
  background-color: rgba($light-blue, .1);
  margin-right: 0;
  margin-left: 0;


}
.lt-darkblue-background {
  background-color: rgba($dark-blue, .25);
  margin-right: 0;
  margin-left: 0;
}
.ltblue-background-extended {
  background-color: rgba($blue, .1);
  margin-right: -50px;
}

.light-blue-background-extended {
  background-color: #e4f7fd;
  margin-right: -50px;
  margin-left: -50px;
}
.blue-background-extended {
   background-color: $blue;
   margin-right: -50px;
    color: $white;
 }
.blue-background {
  background-color: $blue;
  color: $white;
}
.blue-background-extended,
.blue-background {
  .text-link {
    color:$aon-blue;

    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
    }
  }
}

.icon-with-text {
  display: grid;
  grid-template-columns: 75px 3fr;
  grid-gap: 10px;
  align-items: start;

  p {
    margin-top: 0;
    margin-bottom: 5px;
  }

  img {
    height: 75px;
    width: 75px;
  }
}
.blue-horizontal-line {
  border-top: 3px solid $light-blue;
}

.blue-bg-imgs{
  position: absolute;
  top: 10%;
  left: 0;
  max-width: 100%;

  &.centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.blue-bg-japan{
  position: absolute;
  top: 10%;
  right: 0;
  max-width: 100%;
}

.blue-bg-hexagon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;

  @media screen and (max-width: 991px) {
    bottom: 200px;
  }
}

.rx-table{
  .row{
    display: flex;
    justify-content: space-between;
    padding: 7px 15px;

  }
  .row:nth-child(2n-1){
    background-color: rgba($light-blue, .1);
  }
  .row > div:first-child{
    max-width: 80%;
  }
  button {
    color: $white;
    background-color: $blue;
    font-size: 14px;
    height: 26px;
    width:80px;
    border: none;
    border-radius: 5px;
  }
  button:hover{
    background-color: $light-blue;
  }
}

.new-light-blue-bg-extended {
  background-color: #e4f7fd;
}

.light-blue-bordered-box {
  padding: 1.75rem 1.25rem;
  background-color: $white;
  border: 4px solid $light-blue;
  border-radius: 10px;
}

.black-bordered-box {
  padding: 1.75rem 1.25rem;
  background-color: $white;
  border: 1px solid $black;
}

.one-fifteen-three,
.one-fifteen-ten,
.two-five-seven {
  margin-right: 0;
  margin-left: 0;
}

.one-fifteen-three,
.two-five-seven {
  color: $white;
}

.one-fifteen-three {
  background-color: #010206;
}

.one-fifteen-ten,
.one-twenty-three,
.two-five-seven {
  background: $grey-light;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.two-five-seven {
  @media screen and (max-width: 991px) {
    background-position: bottom;
  }
}

.one-twenty-three {
  padding: 5rem 0;

  @media screen and (max-width: 991px) {
    padding: 3rem  0;
  }
}

.icon-headquarters {
  vertical-align: sub;
  width: 22px;
  height: 22px;
}

.responsive-borders {
  border-left: 1px solid $grey-mid-light;
  border-right: 1px solid $grey-mid-light;

  @media screen and (max-width: 991px) {
    border-left: none;
    border-right: none;
    border-top: 1px solid $grey-mid-light;
    border-bottom: 1px solid $grey-mid-light;
  }
}

hr {
  &.border-mission-yellow {
    border-top: 10px solid $missionYellow;
    margin-top: 0;
    margin-bottom: 5px;
  }
  &.border-mission-yellow-green {
    border-top: 10px solid $missionYellowGreen;
    margin-top: 0;
    margin-bottom: 5px;
  }
  &.border-mission-green {
    border-top: 10px solid $missionGreen;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.light-blue-header {
    background-color: #e4f7fd;
    padding: 50px;
}

// VUE Tab Component (aka the AUDIO ACCORDION)
.tabs-app,
#audioAccordion {
  a.card-tab,
  a.card-audio {
    color: $almost-black;
    word-break: unset;
    word-wrap: unset;
    border-top: 1px solid $aon-blue;
    border-right: none;
    border-bottom: 1px solid $aon-blue;
    border-left: none;
    border-collapse: collapse;
    border-radius: 0;
    background-image: $white;
    cursor: pointer;

    &:first-child {
      border-top-width: 2px;
    }
    &:last-child {
      border-bottom-width: 2px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: $dark-blue;
    }
    &.active,
    &[aria-expanded="true"]{
      background-color: #e4f7fd;
      pointer-events: none;
    }

    img {
      max-width: 75%;
    }
    .card-body {
      padding-left: 0;
    }
    .card-title {
      margin-bottom: 0;
    }
    .card-text {
      font-size: 14px;
      line-height: 1;
      margin-bottom: 0;
    }
  }

  .one-twenty-three {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .collapse {
    height: 100%;
  }
  .audio-content-wrapper {
    width: 100%;
    padding: 3rem;
    background-color: rgba($white, .9);
  }
}

.bg-unit {
  //height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  padding: 10px;
  color: $white;
  background-color: $unitButton;
  text-align: left;

  .btn {
    white-space: nowrap;
    margin-left: 10px;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    display: block;
    div {
      display: block;
    }
    .btn {
      margin-top: 10px;
      margin-left: 0;
    }
  }


}
