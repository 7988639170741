// PAGINATION.SCSS

.pagination {
  display: flex;
  list-style: none;
  border-radius: 0px;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $grey-text;
  background-color: $white;
  border: 1px solid $grey-light;
  font-size: 12px;

  &:hover {
    color: $grey-text;
    text-decoration: none;
    background-color: $grey-bg;
    border-color: $grey-light;
  }

  &:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255,255,255,.25);
  }

  // Opinionated: add "hand" cursor to non-disabled .page-link elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

.page-item {
  &:first-child {
    .page-link {
      margin-left: 0;
      border-radius: 0;
    }
  }
  &:last-child {
    .page-link {
      border-radius: 0;
    }
  }

  &.active .page-link {
    z-index: 1;
    color: $white;
    background-color: $aon-blue;
    border-color: $aon-blue;
  }

  &.disabled .page-link {
    color: $grey-mid-light;
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
    background-color: $white;
    border-color: $grey-light;
  }
}
