// LANGUAGES.SCSS

.navbar-nav.right.dropdown {
  @media screen and (min-width: 992px) {
    margin-left: auto !important;

    #navbarDropdownLangLink {
      font-size: 10px;
    }
  }
}

.lang-fr,
.lang-pt,
.lang-es {
  .navbar.primary .navbar-nav .nav-link {
    margin: 2px 8px;
  }
  .btn {
    word-break: normal;
  }
  .btn-launch,
  .btn-show {
    min-width: 126px;
    width: auto !important;
    white-space: nowrap !important;
  }
}

.lang-fr,
.lang-pt,
.lang-es {
  .navbar.primary .navbar-nav .nav-link {
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 12px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 16px;
    }
  }
}
.lang-fr,
.lang-pt {
  .navbar.primary .navbar-nav .nav-link {
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 10px;
    }
  }
}
.lang-pt {
  .navbar.primary .navbar-nav .nav-link {
    @media screen and (min-width: 1200px) {
      font-size: 14px;
    }
  }
}
