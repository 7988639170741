// FONTS.SCSS

// .woff2 - Chrome 26+, Opera 23+, Firefox 39+
// .woff - Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+


/* Roboto Light */
@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../fonts/roboto/roboto-light.woff2') format('woff2'),
       url('../fonts/roboto/roboto-light.woff') format('woff');
}
/* Roboto Regular */
@font-face {
  font-family: 'Roboto Regular';
  src: local('Roboto Regular'), local('Roboto-Regular'),
       url('../fonts/roboto/roboto-regular.woff2') format('woff2'),
       url('../fonts/roboto/roboto-regular.woff') format('woff');
}
/* Roboto Medium */
@font-face {
  font-family: 'Roboto Medium';
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../fonts/roboto/roboto-medium.woff2') format('woff2'),
       url('../fonts/roboto/roboto-medium.woff') format('woff');
}
/* Roboto Bold */
@font-face {
  font-family: 'Roboto Bold';
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../fonts/roboto/roboto-bold.woff2') format('woff2'),
       url('../fonts/roboto/roboto-bold.woff') format('woff');
}


/* Roboto Slab Thin */
@font-face {
  font-family: 'Roboto Slab Thin';
  src: local('Roboto Slab Thin'), local('RobotoSlab-Thin'),
       url('../fonts/roboto-slab/roboto-slab-thin.woff2') format('woff2'),
       url('../fonts/roboto-slab/roboto-slab-thin.woff') format('woff');
}
/* Roboto Slab Light */
@font-face {
  font-family: 'Roboto Slab Light';
  src: local('Roboto Slab Light'), local('RobotoSlab-Light'),
       url('../fonts/roboto-slab/roboto-slab-light.woff2') format('woff2'),
       url('../fonts/roboto-slab/roboto-slab-light.woff') format('woff');
}
/* Roboto Slab Regular */
@font-face {
  font-family: 'Roboto Slab Regular';
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
       url('../fonts/roboto-slab/roboto-slab-regular.woff2') format('woff2'),
       url('../fonts/roboto-slab/roboto-slab-regular.woff') format('woff');
}
/* Roboto Slab Bold */
@font-face {
  font-family: 'Roboto Slab Bold';
  src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'),
       url('../fonts/roboto-slab/roboto-slab-bold.woff2') format('woff2'),
       url('../fonts/roboto-slab/roboto-slab-bold.woff') format('woff');
}
