// RANGE.SCSS

.range-wrap {
  // font-weight: 300;
  font-family: 'Roboto Regular', Helvetica, sans-serif;
  font-size: 14px;
  
  &.self {
    margin: 20px 0px 60px 0px;
  }

  &.post {
    margin: 40px 0px;
  }


  .selfcheck-range {
    -webkit-appearance: none;
    width: 100%;
    height: 20px;
    background: $grey-bg;
    outline: none;
    opacity: 1;
    border-radius: 20px;
    overflow: hidden;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 22px;
      background: $grey-text;
      cursor: pointer;
      border-radius: 6px;
      box-shadow: -500px 0 0 490px $aon-blue;
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 22px;
      background: $grey-text;
      cursor: pointer;
      border-radius: 6px;
      box-shadow: -500px 0 0 490px $aon-blue;
    }

    &::-moz-range-progress {
      background-color: $aon-blue;
    }

    &::-moz-range-track {
      background-color: $grey-bg;
    }

    &::-ms-fill-lower {
      background-color: $aon-blue;
    }
    &::-ms-fill-upper {
      background-color:  $grey-bg;
    }
  }


  .postcheck-range {
    -webkit-appearance: none;
    width: 100%;
    height: 20px;
    background: $grey-bg;
    outline: none;
    opacity: 1;
    border-radius: 20px;
    overflow: hidden;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 22px;
      background: $grey-text;
      cursor: pointer;
      border-radius: 6px;
      box-shadow: -500px 0 0 490px $aon-yellow;
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 22px;
      background: $grey-text;
      cursor: pointer;
      border-radius: 6px;
      box-shadow: -500px 0 0 490px $aon-yellow;
    }

    &::-moz-range-progress {
      background-color: $aon-yellow;
    }

    &::-moz-range-track {
      background-color: $grey-bg;
    }

    &::-ms-fill-lower {
      background-color: $aon-yellow;
    }
    &::-ms-fill-upper {
      background-color:  $grey-bg;
    }
  }

  .ticks {
    display: flex;
    justify-content: space-between;
    height: 6px;
    margin: -6px 6px 0 6px;
    font: 10px Helvetica;
    counter-reset: count -1;

    > div {
      height: 100%;
      width: 1px;
      background: $grey-mid-light;
      counter-increment: count 1;
    }

    > div::before {
      display: block;
      content: counter(count,decimal);
      transform: translate(-50%, 70%);
      text-align: center;
      width: 16px;
      color: $grey-mid-light;
    }
  }
}
