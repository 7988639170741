lxp-self-progress {
  color: $grey-mid-light;
  font-size: 12px;
}

lxp-self-progress .form-control {
  width: 100px;
  height: 30px;
  font-size: 12px;
  padding: 4px;
  background-color: $grey-bg;
}

lxp-self-progress .form-control.not-started {
  border: 1px solid $bootstrap-grey;
}

lxp-self-progress .form-control.in-progress {
  border: 1px solid $aon-yellow;
}

lxp-self-progress .form-control.completed {
  border: 1px solid $aon-green;
}

.no-self-progress {
  margin-right: 8px;
  width: 140px;
  display: inline-block;
  margin-top: -22px;

  @media screen and (max-width: 767px) {
    width: 104px;
    visibility: hidden;
  }

  &.inner-page {
    margin-top: -18px;
    width: auto;
  }

  .status-label {
    font-size: 10px;
    color: $grey-text;
    // margin-left: 2px;
  }

  .status-box {
    background-color: $white;
    border-radius: 4px;
    padding: 10px;
    width: 100px;
    height: 30px;
    font-size: 12px;
    padding: 5px 10px;
    &.none,
    &.notstarted {
      border: 1px solid $bootstrap-grey;
    }
    &.inprogress,
    &.in-progress {
      border: 1px solid $aon-yellow;
    }
    &.completed,
    &.complete {
      border: 1px solid $aon-green;
    }
  }
}

.lxp-self-progress-wrapper {
  margin-right: 8px;
  width: 108px;
  display: inline-block;
}

.lxp-self-progress-wrapper .status-label {
  font-size: 10px;
  color: $grey-text;
  // margin-left: 2px;

  @media screen and (max-width: 767px) {
    visibility: hidden;
  }
}

.course-content .lxp-self-progress-wrapper,
.resource .lxp-self-progress-wrapper {
  margin-top: -18px;
}

@media screen and (max-width: 767px) {
  .learning_paths lxp-self-progress,
  .playlist lxp-self-progress,
  .learning_paths lxp-self-progress .form-control,
  .playlist lxp-self-progress .form-control {
    margin-left: 118px;
    width: 200px;
  }
}
