// LEARNING_PATH.SCSS

.learning_path-banner {
  background-color: $grey-bg;
  background-size: cover;

  .prompt {
    background-color: darken(rgba($aon-red, 0.7), 20%);
    padding: 40px 20px;
  }

  h1 {
    color: $white;
    font-family: "Roboto Slab Thin", Georgia, serif;
    margin: 0px;
    font-size: 28px;
  }

  .description {
    color: $white;
    font-size: 15px;
    font-family: "Roboto Light", Helvetica, sans-serif;
    margin-top: 12px;
  }
}

.learning_path-banner {
  //background-image: url("/static/img/demo/banner-learningPaths-pedestrians.jpg");
}

.learning_path_group-banner {
  background-color: $white;
  background-size: cover;

  .prompt {
    background-color: $white;
    padding: 40px 20px;
  }

  h1 {
    color: $grey-text;
    font-family: "Roboto Slab Light", Georgia, serif;
    margin: 0px;
    font-size: 28px;
  }

  .description {
    color: $grey-text;
    font-size: 15px;
    font-family: "Roboto Light", Helvetica, sans-serif;
    margin-top: 12px;
    overflow: hidden;
  }
}

.learning_path {
  padding: 0px;

  @media screen and (max-width: 575px) {
    padding: 0px 15px;
  }

  .bucket,
  .path_group {
    background-color: $white;
    padding: 20px;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

    h2 {
      font-family: "Roboto Slab Regular", Georgia, serif;
      font-size: 28px;
    }

    h3 {
      font-family: "Roboto Slab Regular", Georgia, serif;
      font-size: 24px;
    }

    .description {
      color: $grey-text;
      line-height: inherit;
      // font-weight: 300;
      font-family: "Roboto Regular", Helvetica, sans-serif;
      font-size: 14px;
      word-break: break-word;
    }

    hr {
      @media screen and (max-width: 991px) and (min-width: 768px) {
        margin-top: 3rem;
      }
    }

    hr:last-child {
      display: none;
    }

    .list-group {
      &#CreateClientValue,
      &#EnableInnovation,
      &#DeliverBusinessResults,
      &#DevelopTeams {
        // padding-top: 80px;
        // margin-top: -80px;
      }

      .list-group-item {
        padding: 10px 0px;
        border: none;

        &:hover,
        &:active {
          bborder: none;
          background-color: $white;
        }

        img {
          height: 80px;
          width: 80px;
          min-width: 80px;
          object-fit: cover;
        }

        h3 {
          font-family: "Roboto Slab Regular", Georgia, serif;
          font-size: 24px;

          a {
            color: $grey-text;
            word-break: normal;

            &:hover {
              color: lighten($grey-text, 20%);
              text-decoration: none;
            }
          }

          small {
            font-size: 22px;
          }
        }

        .content-details {
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          color: $grey-mid-light;
          margin-bottom: 6px;
        }

        p {
          margin-bottom: 0px;
          line-height: inherit;
          word-break: normal;
          color: $grey-text;
          font-family: "Roboto Regular", Helvetica, sans-serif;
          font-size: 14px;

          &.description {
            // max-width: 770px;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
          }
        }

        .bucket-description {
          margin-left: 98px;
          line-height: inherit;
          color: $grey-text;
          // font-weight: 300;
          font-family: "Roboto Regular", Helvetica, sans-serif;
          font-size: 14px;
        }

        .description.truncate {
          display: block;
          display: -webkit-box;
          max-width: 100%;
          max-height: 40px;
          margin: 0 auto;
          line-height: 20px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .quick-links {
          margin-top: 10px;
          display: inline-flex;
          align-items: center;

          @media screen and (max-width: 991px) {
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            padding: 0px 0px 0px 94px;
          }
          @media screen and (max-width: 767px) {
            position: relative;
            padding: 0px 0px 0px 0px;
            display: block;
            padding-right: 96px;
          }
          @media screen and (max-width: 575px) {
            margin-left: 0px;
          }

          a,
          .add-to-playlist,
          .add-to-plan {
            font-size: 12px;
            color: $grey-text;
            background-color: $grey-light;
            padding: 4px 6px;
            border-radius: 4px;

            &:hover {
              text-decoration: none;
              color: lighten($grey-text, 20%);
              cursor: pointer;
            }
          }
        }

        .btn-explore,
        .btn-launch,
        .btn-show,
        .btn-add {
          width: 116px;
          height: 30px;
        }
        .btn-add {
          width: 160px;
          height: 30px;
        }
      }
    }

    .collapse,
    .collapsing {
      margin-left: 95px;

      label .self-check {
        color: $aon-blue;
        font-family: "Roboto Slab Regular", Georgia, serif;
        font-size: 20px;
      }

      label .post-check {
        color: $aon-yellow;
        font-family: "Roboto Slab Regular", Georgia, serif;
        font-size: 20px;
      }

      .section-title {
        font-family: "Roboto Slab Regular", Georgia, serif;
        color: $grey-text;
        font-size: 20px;
        margin-top: 20px;

        @media screen and (max-width: 991px) and (min-width: 768px) {
          margin-top: 40px;
        }
      }

      .section-description {
        font-family: "Roboto Regular", Helvetica, sans-serif;
        font-size: 14px;
      }

      .list-group.course {
        .list-group-item {
          img {
            width: 80px;
            height: 80px;
            min-width: 80px;
            object-fit: cover;
          }

          h5 {
            font-family: "Roboto Slab Regular", Georgia, serif;
            font-size: 18px;

            a {
              color: $aon-teal;
              word-break: normal;

              &:hover {
                color: lighten($aon-teal, 10%);
                text-decoration: none;
              }
            }
          }

          .quick-links {
            a {
              color: $grey-text;

              &:hover {
                color: lighten($grey-text, 20%);
              }
            }
          }
        }
      }
    }
  }
  .bucket .btn-show {
    width: 106px !important;
  }
  .bucket img.super-bucket-image {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 1199px) {
  .learning_path {
    .bucket,
    .path_group {
      .list-group {
        .list-group-item {
          p {
            &.description {
              // max-width: 590px;
            }
          }
          .progress-pill {
            width: 160px;
          }

          .btn-launch,
          .btn-review,
          .btn-resume,
          .btn-show,
          .btn-explore {
            width: 122px;
          }
          .btn-add {
            width: 168px;
          }
        }
        .collapse,
        .collapsing {
          width: 806px;
          float: right;
        }
      }
    }
    .bucket .btn-show {
      width: 110px !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .learning_path {
    .bucket,
    .path_group {
      .list-group {
        .list-group-item {
          .progress-pill {
            width: 200px;
          }

          .btn-launch,
          .btn-review,
          .btn-resume,
          .btn-show,
          .btn-explore {
            width: 146px;
          }

          .btn-add {
            width: 204px;
          }
        }
        .collapse,
        .collapsing {
          width: 566px;
          float: right;

          @media screen and (max-width: 991px) and (min-width: 768px) {
            padding-bottom: 40px;
          }
          
        }
      }
    }
    .bucket {
      .btn-show {
        width: 120px !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .learning_path {
    .bucket,
    .path_group {
      .list-group {
        .list-group-item {
          .quick-links {
            margin-left: 96px;
            position: relative;
            padding: 0px 0px 0px 0px;
            display: block;
            padding-right: 96px;
          }

          .quick-links a,
          .quick-links .add-to-plan,
          .quick-links .add-to-playlist,
          .quick-links input.goal-date {
            display: inline-block;
            width: 100%;
            max-width: 200px;
            text-align: center;
            margin-top: 3px;
          }

          img {
            float: left;
          }

          .w-100 {
            // padding-left: 116px;
          }

          .progress-pill {
            margin: 20px auto 0 auto;
          }

          .btn-launch,
          .btn-show,
          .btn-review,
          .btn-resume,
          .btn-explore {
            width: 100% !important;
            margin-top: 20px;
          }
          .btn-add {
            margin-top: 20px;
            width: 100%;
          }
        }
        .collapse,
        .collapsing {
          width: 100%;
          float: none;
        }
      }
    }
  }

  .learning_path .bucket .collapse,
  .learning_path .path_group .collapse {
    margin-left: 0px;
  }
}
