lxp-likes span {
  font-size: 12px;
  color: $grey-text;
  background-color: $grey-light;
  padding: 4px 10px;
  border-radius: 4px;

  &:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-left: -4px;
  }

  &:hover {
    text-decoration: none;
    color: darken($grey-text, 20%);
    cursor: pointer;
    background-color: darken($grey-light, 5%);
  }
}

lxp-likes {
  @media screen and (max-width: 767px) {
    display: inline-block;
    width: 100%;
    max-width: 200px;
    text-align: left;
    margin-top: 3px;
  }
}
