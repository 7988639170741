.login-left{
  display: flex;
  flex-direction: column;
  padding: 100px 50px 10px 50px;
  justify-content: space-between;
}

.left-bottom-text{
  padding-top: 20px;
}

.login-form{
  display: flex;
  flex-direction: column;


  input{
    margin-bottom: 20px;
    padding: 5px;
  }

  .form-grid{
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

}

.registration-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 100px 50px;
  background-color: rgba(0, 180, 237, 0.3);

}

.register-1{
  padding: 50px;
  color: #4D4F53;
  h3{
    font-weight: 300;
  }
  h4{
    padding-top: 35px;
    font-weight: 400;
    .blue{
      color: $light-blue;
    }
  }
}
.blue{
  color: $light-blue;
}

.tutorial-numbers{
  color:$light-blue;
  font-size: 30px;
  font-weight: 700;
  padding: 10px 8px;
  border: $light-blue solid 1px;
  border-radius: 5px;
}
.blue-box{
  padding: 30px;
  background-color: #DDF7FF;
  border-radius: 11px;
  margin: 30px;

  .white-box{
    margin-left: 5px;
    padding: 5px 50px 5px 5px;
    width: 90%;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
  }

  textarea{
    width: 100%;
  }

}

.registration{
  padding-bottom: 20px;

  form{
    display: grid;
    grid-template-columns: 2fr 3fr;
  }
}

a {
  color: $blue;
}
