// HEADER.SCSS

.navbar.primary {
  background-color: $white;
  z-index: 200;
  border-bottom: #00B4ED 6px solid;

  .navbar-brand {
    display: flex;
    align-items: center;
    margin-right: auto;

    .title,
    .subtitle {
      color: $blue;
      display: block;
      text-transform: uppercase;
      line-height: 1;
    }
    .title {
      font-size: 25px;
      font-weight: bold;
    }
    .subtitle {
      font-size: 12px;
    }
    .logo-header {
      height: 55px;
      margin-right: 10px
    }
  }

  .navbar-nav {
    .nav-link {
      color: $blue;
      padding: 2px;
      margin: 2px 12px;
      font-size: 16px;
      font-family: inherit;

      &:hover, &:focus, &.active {
        color: $grey-dark;
      }
    }
  }

  .form-control.search {
    width: 140px;
    background-color: $grey-bg;
    border: 1px solid $grey-bg;
    border-radius: 10px;
    height: 25px;
    font-size: 13px;
    padding-left: 10px;
  }
}

.navbar.secondary {
  margin-top: 78px;
  background-color: $black;
  z-index: 100;

  .navbar-nav {
    .nav-link {
      padding: 0px 36px 0px 0px;
      font-size: 14px;
      color: $white;
      word-break: normal;
    }

    .dropdown-menu {
      &.show {
        :focus {
          background-color: $grey-bg;
          color: $grey-text;
        }
      }

      .dropdown-divider:last-child {
        display: none;
      }
      a {
        text-decoration: none;
      }
    }

    .dropdown-item {
      font-size: 14px;
      &.disabled.path-bucket {
        color: $grey-text;
      }
    }
  }
}

.feedback-link {
  position: fixed;
  top: 190px;
  right: 0px;
  z-index: 999999;
  background-color: rgba($black, 0.8);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 18px;
  padding: 20px 6px;
  // Change in how the text is rotated to better handle add'l languages
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  text-orientation: sideways-right;

  a {
    color: $white;
    text-decoration: none;
  }
  // safari 10+ position fix
  @media not all and (min-resolution: 0.001dpcm) {
    right: 12px;
  }
}

// Browser Checker
#buorg {
  background-color: rgba(255, 255, 255, 0.95);
  height: 100%;
  padding-top: 100px;
}
#buorgul {
  background: #5eb6e4 !important;
  box-shadow: none !important;
}

.buorg-pad {
  text-align: left;
  max-width: 1000px;
  margin: 0 auto;
  font-size: 22px;
  background-color: $white;
  border: 2px solid $grey-bg;
  border-radius: 20px;
  padding: 20px !important;
  margin-top: 20px;

  a {
    color: $aon-blue;
    text-decoration: none;
  }
}

.buorg-directions {
  margin-top: -20px;
  padding: 20px 26px;
  font-size: 16px;
  line-height: 20px;

  a {
    color: $aon-blue;
    text-decoration: none;
  }

  a.btn-tutorial {
    background-color: $aon-teal;
    color: $white;
    &:hover {
      background-color: lighten($aon-teal, 5%);
      color: $white;
    }
  }
  a#buorgig {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    background-color: $grey-mid-dark;
    color: $white;
    &:hover {
      background-color: lighten($grey-mid-dark, 5%);
      color: $white;
    }
  }
}

// Preview Alert
.preview-alert {
  background-color: $aon-red;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999;

  .alert {
    color: $white;
    margin-bottom: 0px;
    text-align: center;

    .close:hover {
      color: $white;
    }

    a {
      color: $white;
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .navbar.primary {
    .navbar-nav {
      .nav-link {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .navbar.primary {
    .navbar-nav {
      .nav-link {
        padding: 12px 2px;

        &:hover {
          border-top: 3px solid transparent;
          color: $aon-red;
        }

        &.active {
          border-top: 3px solid transparent;

          &:hover {
            border-top: 3px solid transparent;
            color: $aon-red;
          }
        }
      }
    }

    .form-control.search {
      width: 300px;
    }
  }

  .navbar.secondary {
    .btn-group {
      display: block;

      .dropdown-toggle {
      }

      .dropdown-menu {
        background: $black;

        a.dropdown-item {
          color: $white;
          font-size: 14px;

          &:hover {
            color: lighten($aon-blue, 10%);
            background-color: $black;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .navbar.secondary.learning-plan {
    .navbar-nav {
      display: block;

      .nav-link {
        padding: 4px 10px;
      }
    }
  }
}
