// RECOMMENDED.SCSS

.recommended-banner {
  //background-image: url('/static/img/demo/banner-learningPaths-shoes.jpg');
  background-color: $grey-bg;
  background-size: cover;

  .prompt {
    background-color: rgba($aon-red, .7);
    padding: 40px 20px;
  }

  h1 {
    color: $white;
    font-family: 'Roboto Slab Thin', Georgia, serif;
    margin: 0px;
    font-size: 28px;

    & small {
      font-family: 'Roboto Medium', Helvetica, sans-serif;
      font-size: 16px;
    }
  }
  .description {
    color: $white;
    font-size: 15px;
    font-family: 'Roboto Light', Helvetica, sans-serif;
    margin-top: 12px;
  }
}
