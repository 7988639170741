// JOURNAL-DSI.SCSS

.journal {
  .custom-notes {
    a.journal-path {
      width: 100%;
      display: flex;
      margin: 0;
      padding: 10px;
      font-size: 16px;
      line-height: normal;
      color: $black-link !important;
      border: 1px solid $blue;
      border-radius: 5px;
      cursor: pointer;

      &:hover,
      &.active {
        border-color: $light-blue;
        text-decoration: none;
      }
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 20px;
    border: 1px solid $light-blue;
    border-radius: 5px;
  }



  //.reflection-journal {
  //  background-color: $white;
  //  padding: 20px;
  //  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
  //
  //  h2 {
  //    font-family: "Roboto Slab Regular", Georgia, serif;
  //  }
  //
  //  .sub-groups {
  //    border-right: 1px solid $grey-light;
  //    background-color: $white;
  //
  //    @media screen and (max-width: 991px) {
  //      border-right: none;
  //      border-bottom: 1px solid $grey-light;
  //      z-index: 1;
  //    }
  //
  //    .list-group {
  //      .list-group-item {
  //        border: none;
  //        background: transparent;
  //        padding: 6px 0px 0px 0px;
  //        margin-right: 20px;
  //
  //        h3 {
  //          font-family: "Roboto Slab Regular", Georgia, serif;
  //          font-size: 16px;
  //          margin: 10px 0px;
  //          padding-left: 22px;
  //          text-indent: -22px;
  //
  //          a {
  //            color: $aon-teal;
  //            word-break: normal;
  //
  //            &:hover {
  //              color: lighten($aon-teal, 5%);
  //              text-decoration: none;
  //            }
  //
  //            &.active {
  //              color: $grey-text;
  //
  //              &:hover {
  //                color: $grey-text;
  //              }
  //            }
  //          }
  //        }
  //      }
  //
  //      .journal-section {
  //        li {
  //          list-style-type: none;
  //          margin-left: -20px;
  //          margin-right: 20px;
  //          margin-bottom: 12px;
  //          line-height: 20px;
  //
  //          a {
  //            color: $aon-teal;
  //            font-size: 15px;
  //            word-break: normal;
  //
  //            &:hover {
  //              text-decoration: none;
  //            }
  //          }
  //        }
  //      }
  //    }
  //
  //  }
  //
  //  .courses {
  //    border: 1px solid $grey-light;
  //    border-left: none;
  //    background-color: $white;
  //
  //    .list-group {
  //      .list-group-item {
  //        border: none;
  //        background: transparent;
  //
  //        h4 {
  //          font-family: "Roboto Slab Regular", Georgia, serif;
  //          font-size: 15px;
  //
  //          a {
  //            color: $grey-text;
  //            word-break: normal;
  //
  //            &:hover {
  //              color: lighten($grey-text, 30%);
  //              text-decoration: none;
  //            }
  //
  //            &.active {
  //              color: $grey-text;
  //
  //              &:hover {
  //                color: $grey-text;
  //              }
  //            }
  //          }
  //        }
  //      }
  //    }
  //  }
  //
  //  .textarea {
  //    background-color: $white;
  //    border-radius: 4px;
  //    border-top-left-radius: 0px;
  //    border-bottom-left-radius: 0px;
  //    border-left: 0px;
  //    padding: 0px;
  //
  //    @media screen and (max-width: 991px) {
  //      flex-basis: auto;
  //    }
  //
  //    .form-group {
  //      padding: 10px 20px;
  //      overflow: scroll;
  //      margin-top: -140px;
  //    }
  //
  //    label {
  //      font-family: "Roboto Slab Regular", Georgia, serif;
  //      font-size: 18px;
  //      color: $aon-teal;
  //    }
  //
  //    .journal-question {
  //      font-size: 14px;
  //    }
  //
  //    .last-edited {
  //      font-size: 12px;
  //      color: $grey-mid-light;
  //    }
  //
  //    .journal-final-title {
  //      font-size: 22px;
  //      color: $grey-text;
  //    }
  //  }
  //
  //  .journal-section-div {
  //    border: 1px solid transparent;
  //  }
  //
  //  .journal-section-div:before {
  //    content: "";
  //    display: block;
  //    height: 140px;
  //    visibility: hidden;
  //  }
  //}
}
