lxp-async-button[method=POST] .add{
  display: inline;
}

lxp-async-button[method=POST] .remove{
  display: none;
}

lxp-async-button[method=DELETE].add-to-plan {
  background-color: lighten($aon-blue, 20%);
}
lxp-async-button[method=DELETE].not-plan {
  pointer-events: none;
}

.quick-links lxp-async-button[method=DELETE].add-to-plan {
  background-color: lighten($grey-light, 7%) !important;
  color: lighten($grey-text, 40%) !important;

}

lxp-async-button[method=DELETE].add-to-playlist {
  background-color: lighten($grey-light, 7%) !important;
  color: lighten($grey-text, 40%) !important;
}
lxp-async-button[method=DELETE].not-playlist {
  pointer-events: none;
}
lxp-async-button[method=DELETE].add-to-playlist.removable,
lxp-async-button[method=DELETE].add-to-plan.removable {
  background-color: $grey-light !important;
  color: $grey-text !important;
}

lxp-async-button[method=DELETE] .add{
  display: none;
}

lxp-async-button[method=DELETE] .remove{
  display: inline;
}
