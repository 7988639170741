// RESOURCES.SCSS

.resource {
  padding: 0px;

  @media screen and (max-width: 575px) {
    padding: 0px 15px;
  }

  .container {
    background-color: $white;
    padding: 15px;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

    h2 {
      font-family: "Roboto Slab Regular", Georgia, serif;
      font-size: 28px;
    }

    .description {
      font-family: "Roboto Regular", Helvetica, sans-serif;
      font-size: 14px;
    }

    .resources-content {
      font-family: "Roboto Regular", Helvetica, sans-serif;
      font-size: 14px;
    }

    .list-group {
      border-top: 1px solid $grey-light;
      padding: 10px 0px;

      .list-group-item {
        padding: 10px 0px;
        border: none;

        &:hover,
        &:active {
          bborder: none;
          background-color: $white;
        }

        img {
          height: 100px;
          width: 100px;
        }

        h3 {
          font-family: "Roboto Slab Regular", Georgia, serif;
          font-size: 22px;

          a {
            color: $grey-text;
            word-break: normal;

            &:hover {
              color: lighten($grey-text, 20%);
              text-decoration: none;
            }
          }
        }

        p {
          margin-bottom: 0px;
          line-height: 20px;
          word-break: normal;

          &.description {
            max-width: 660px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .content-details {
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          color: $grey-mid-light;
        }

        .quick-links {
          margin-top: 10px;
          display: inline-flex;
          align-items: center;

          @media screen and (max-width: 767px) {
            position: relative;
            padding: 0px 0px 0px 0px;
            display: block;
          }

          a,
          .add-to-playlist {
            font-size: 12px;
            color: $grey-text;
            background-color: $grey-light;
            padding: 4px 6px;
            border-radius: 4px;

            &:hover {
              text-decoration: none;
              color: lighten($grey-text, 20%);
              cursor: pointer;
            }
          }
        }

        .description {
          font-family: "Roboto Regular", Helvetica, sans-serif;
          font-size: 14px;
        }

        .add-to-plan {
          width: 132px;
        }

        .btn-launch,
        .btn-show,
        .btn-review,
        .btn-resume {
          width: 126px;
        }

        .btn-cog {
          margin-right: 20px;
        }
      }
    }

    .content-details {
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: $grey-mid-light;
    }

    .accordion {
      .card {
        .card-header {
          background-color: $white;

          h5 .btn-link {
            color: $aon-teal;
            font-family: "Roboto Slab Regular", Georgia, serif;
            font-size: 20px;
            text-align: left;

            &:hover {
              color: lighten($aon-teal, 10%);
              text-decoration: none;
            }
            &:focus {
              color: $grey-text;
              text-decoration: none;
            }
          }
        }
      }
    }

    .quick-links {
      margin-top: 10px;
      display: inline-flex;
      align-items: center;

      @media screen and (max-width: 767px) {
        position: relative;
        padding: 0px 0px 0px 0px;
        display: block;
      }

      a,
      .add-to-playlist {
        font-size: 12px;
        color: $grey-text;
        background-color: $grey-light;
        padding: 4px 6px;
        border-radius: 4px;

        &:hover {
          text-decoration: none;
          color: lighten($grey-text, 20%);
          cursor: pointer;
        }
      }
    }

    .highlight {
      background-color: $grey-bg;
      padding: 10px 20px;
      border-radius: 4px;
    }
  }
}
