// SEARCH.SCSS

.search-banner {
  // background-image: url('/static/img/demo/banner-learningPaths-telescope.jpg');
  background-color: $aon-blue;
  background-size: cover;

  .prompt {
    // background-color: rgba($aon-teal, .7);
    padding: 30px 20px;

    @media screen and (max-width: 991px) {
      display: block !important;
    }
  }

  h1 {
    color: $white;
    font-family: "Roboto Slab Thin", Georgia, serif;
    margin: 0px;
    font-size: 28px;

    & small {
      font-family: "Roboto Medium", Helvetica, sans-serif;
      font-size: 16px;
    }
  }

  .results-count {
    color: $white;
    font-size: 12px;
    font-family: "Roboto Regular", Helvetica, sans-serif;
    margin-top: 12px;

    a {
      color: $grey-text;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .sort-by-select {
    width: 25%;
    margin-left: auto;

    @media screen and (max-width: 991px) {
      width: 100%;
      justify-content: end;
    }

    .select-label {
      color: $white;
      font-size: 12px;
      font-family: "Roboto Regular", Helvetica, sans-serif;
      width: 100%;
      text-align: right;

      @media screen and (max-width: 991px) {
        width: auto;
        min-width: 86px;
      }
    }

    select {
      @media screen and (max-width: 991px) {
        width: 150px;
      }
    }
  }
}

.search {
  padding: 0px;

  @media screen and (max-width: 575px) {
    padding: 40px 15px !important;
  }

  hr {
    @media screen and (max-width: 991px) and (min-width: 768px) {
      margin-top: 3rem;
    }
  }

  .search-filter {
    background-color: $white;
    padding: 20px;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
    margin-right: 20px;

    .search-buttons {
      height: 50px;
    }

    a {
      .fa-plus {
        display: inline;
        color: $white;
      }
    }

    h3 {
      font-size: 22px;
      margin-bottom: 0px !important;
    }

    .filter-group {
      margin-top: 20px;

      h4 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 6px;
      }

      .form-group {
        margin-bottom: 2px;
        font-size: 14px;
      }
    }

    &.filter-fixed {
      position: fixed;
      top: 166px !important;
      width: 265px;

      @media screen and (max-width: 1199px) {
        top: 204px;
        width: 220px;
      }

      @media screen and (max-width: 991px) {
        position: initial;
        top: initial;
        width: initial;
      }
    }
  }

  .search-results {
    background-color: $white;
    padding: 30px;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

    h2 {
      font-family: "Roboto Slab Light", Georgia, serif;
    }

    .list-group {

      .list-group-item {
        // margin-bottom & padding reduced BY HALF because quick-links & lxp-likes removed
        margin-bottom: 10px;
        padding: 0 0 15px 0;
        border: none;
        border-bottom: 1px solid $grey-light;

        &:hover,
        &:active {
          bborder: none;
          background-color: $white;
        }

        img {
          width: 80px;
          height: 80px;
          min-width: 80px;
          object-fit: cover;
        }

        h3 {
          font-family: "Roboto Slab Regular", Georgia, serif;
          font-size: 26px;

          a {
            color: $grey-text;
            word-break: normal;

            &:hover {
              color: lighten($grey-text, 20%);
              text-decoration: none;
            }
          }
        }

        .content-details {
          // DE-365: global display none
          display: none;

          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          color: $grey-mid-light;
          margin-bottom: 6px;
        }

        p {
          margin-bottom: 0px;
          line-height: 20px;
          word-break: normal;

          &.description {
            // max-width: 500px;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            font-family: "Roboto Regular", Helvetica, sans-serif;
            font-size: 14px;
          }
        }

        .description {
          font-family: "Roboto Regular", Helvetica, sans-serif;
          font-size: 14px;

          .truncate {
            display: block;
            display: -webkit-box;
            max-width: 100%;
            max-height: 44px;
            margin: 0 auto;
            line-height: inherit;
            // font-weight: 300;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }



        .quick-links {
          width: 100%;
          margin-top: 10px;
          display: inline-flex;
          align-items: center;

          a,
          .add-to-playlist,
          .add-to-plan {
            font-size: 12px;
            color: $grey-text;
            background-color: $grey-light;
            padding: 4px 6px;
            border-radius: 4px;

            &:hover {
              color: lighten($grey-text, 20%);
              cursor: pointer;
            }
          }
        }

        .progress-pill {
          width: 130px;
        }

        .btn-launch,
        .btn-resume,
        .btn-review {
          width: 130px;
          height: 30px;
          margin-top: 2px;
        }
      }

      &:last-child {
        .list-group-item {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .search {
    .search-filter {
      .filter-group {
        .form-group {
          font-size: 13px;
        }
      }
    }

    .search-results {
      .list-group {
        .list-group-item {
          p {
            &.description {
              max-width: 370px;
            }
          }
          .progress-pill {
            width: 150px;
          }

          .btn-launch,
          .btn-resume,
          .btn-review {
            width: 142px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .search {
    .search-filter {
      margin: 0px 0px 20px 0px;
      height: 100%;

      .filter-group {
        // float: left;
        width: 20%;
        height: 80%;
        padding: 0px 10px;
        margin: 20px 0px 0px 0px;

        h4 {
          font-size: 13px;
        }

        .form-group {
          font-size: 11px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .search {
    .search-filter {
      height: auto;

      .filter-group {
        float: none;
        width: auto;
        height: auto;
        padding: 0px;
        margin: 0px;
        border-right: none;
        // display: none;
        margin-top: 20px;
      }
    }
    .search-results {
      .list-group {
        .list-group-item {
          .quick-links a,
          .quick-links .add-to-plan,
          .quick-links .add-to-playlist,
          .quick-links input.goal-date {
            display: inline-block;
            width: 100%;
            max-width: 200px;
            text-align: center;
            margin-top: 3px;
          }

          .progress-pill {
            margin: 20px auto 0 auto;
          }

          .btn-launch,
          .btn-resume,
          .btn-review {
            width: 100%;
            margin-top: 20px;
          }
        }
      }
    }
  }
}


.search-btn-wrapper {
  display: flex;
  flex-direction: column;

  .no-self-progress {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 6px;
  }
  .no-self-progress .status-box {
    width: auto;
  }

  @media screen and (max-width: 767px) {
    margin-top: -60px;
  }
}
