// TYPOGRAPHY-DSI.SCSS

.grey-dark {
  color: $grey-dark;
}

.mission-orange {
    color: $missionOrange;
}
.mission-yellow {
    color: $missionYellow
}
.mission-yellow-green {
    color: $missionYellowGreen;
}
.mission-green {
    color: $missionGreen;
}
.mission-green-blue {
    color: $missionGreenBlue;
}
.mission-light-blue {
    color: $missionLightBlue;
}
.mission-blue {
    color: $missionBlue;
}
.mission-dark-blue {
    color: $missionDarkBlue;
}

.unit-blue {
    color: $unitBlue;
}
