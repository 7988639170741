// SEARCH_AUTOCOMPLETE.SCSS

.aon-ac {
  width: 80%;
  padding: 22px;
  background-color: rgba($black, .75);
  border-radius: 4px;

  .btn-search {
    background-color: $grey-text;
    color: $white;

    &:hover {
      background-color: lighten($grey-text, 5%);
    }
  }
}

.aon-ac form {
  display: inline-block;
  width: 100%;
}

lxp-autocomplete {
  // position: relative;
  // margin:0;
  // padding: 0 !important;
  // box-sizing: border-box;
  width: 100%;

  &.navbar-autocomplete {
    width: auto;
    margin-left: auto;

    lxp-suggestions {
      font-size: 13px;
      width: 260px !important;
      margin-left: -120px;
      margin-top: 2px;
      border: 1px solid $grey-bg;
    }
  }
}

lxp-suggestions {
  background: $white;
  box-sizing: border-box;
  padding: 1em;
  width: 100%;
  display: block;
  position: absolute;
  // top: 100%;
  z-index: 600;
  box-shadow: 0px 20px 20px rgba(0,0,0,0.2);
}

lxp-suggestions mark {
  margin:0;
  padding: 0;
}

nav lxp-suggestions {
  padding: 0;
  font-size: 14px;

}

lxp-suggestions a {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
}

lxp-suggestions a {
  color: $grey-text;
}

lxp-suggestions a:hover,
lxp-suggestions a[active] {
  background-color: $aon-teal;
  color: $white;
  text-decoration: none;

  mark {
    background-color: $aon-teal;
    color: $grey-text;
  }
}

lxp-suggestions a mark {
  background-color: $white;
  color: $aon-teal;
}

lxp-suggestions .btn-all{
  width: auto;
  display: inline-block;
  margin-top: 20px;
  background-color: $grey-bg;
  width: 100%;
}

nav form {
    width: 176px;
}


@media (max-width: 991px){
  .aon-ac {
    width: 100%;
    margin: 0px;
    border-radius: 0px;
  }

  lxp-autocomplete {
    &.navbar-autocomplete {

      lxp-suggestions {
        width: 300px !important;
        margin-left: 0px;
      }
    }
  }
}


@media (max-width: 576px){
  nav form {
      width: auto;
  }

  lxp-autocomplete {
    display: contents;
  }
}
