// DASHBOARD.SCSS

.jumbotron.dashboard {
  position: relative;
  margin: 0px;
  padding: 0px;
  background-color: $aon-blue;
  background-size: cover;
  border-radius: 0px;

  .container {
    // overflow: hidden;
    height: 100%;
    padding: 0px;
    background: transparent;

    .row {
      margin: 0px;

      .prompt {
        background-color: rgba($aon-teal, 0.7);
        padding: 40px 20px;

        h1 {
          color: $white;
          font-family: "Roboto Slab Thin", Georgia, serif;
          margin: 0px;
          font-size: 28px;

          small {
            font-size: 18px;
            line-height: 40px;
          }
        }

        .description {
          color: $white;
          font-family: "Roboto Light", Helvetica, sans-serif;
          font-size: 15px;
          margin-top: 12px;
        }

        h2 {
          color: $white;
          font-family: "Roboto Medium", Helvetica, sans-serif;
          font-size: 16px;
        }

        .btn {
          width: 203px;
          font-size: 14px;
          font-family: "Roboto Slab Light", Georgia, serif;
          margin: 6px;
        }
      }
      .search {
        background: transparent;
        padding: 0px;
      }
    }
  }
}

.dashboard {
  padding: 0px;

  .whats-new,
  .getting-started {
    padding-bottom: 40px;
    padding-left: 0px;
    padding-right: 0px;

    h2 {
      font-family: "Roboto Slab Light", Georgia, serif;
      font-size: 24px;
      margin-bottom: 16px;
    }

    h3 {
      font-family: "Roboto Slab Regular", Georgia, serif;
      font-size: 24px;
    }

    .card {
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

      .card-body {
        h5 {
          font-family: "Roboto Bold", Helvetica, sans-serif;
          font-size: 16px;
          margin-bottom: 4px;

          a {
            color: $grey-text;
            text-decoration: none;
            word-break: normal;

            &:hover {
              color: lighten($grey-text, 20%);
            }
          }
        }

        .card-text {
          font-family: "Roboto Regular", Helvetica, sans-serif;
          font-size: 14px;
          margin-bottom: 14px;
          margin-top: 14px;
          // color: $grey-mid-dark;
        }
      }

      .card-footer {
        background-color: $white;
        border-top: none;

        a.btn {
          font-size: 14px;
          padding: 1px 16px 2px 16px;
        }
      }
    }
  }

  .whats-new {
    .card-img-top {
      height: 160px;
      object-fit: cover;
    }

    .card {
      .card-body {
        margin-bottom: 0px;
        padding-bottom: 0px;
        padding-top: 14px;
      }
      .card-footer {
        margin-top: 0px;
        padding-top: 0px;
        padding-bottom: 16px;
      }
    }
  }

  .getting-started {
    // background-color: $white;

    .container {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
    }

    img {
      width: 160px;
    }

    .card-body {
      padding-bottom: 0px;
    }

    .card-footer {
      margin-left: 176px;
      padding-top: 0px;
      padding-bottom: 20px;
    }
  }

  .recommended-learning {
    background: lighten($aon-blue, 30%);

    .description {
      // font-weight: 300;
      font-family: "Roboto Light", Helvetica, sans-serif;
      font-size: 14px;
    }
  }

  .learning-plan-summary {
    background-color: $white;

    .container {
      .btn.view {
        // font-family: 'Roboto Slab Light', Georgia, serif;
      }

      h2 {
        font-family: "Roboto Slab Light", Georgia, serif;
        font-size: 24px;
      }

      .description {
        // font-weight: 300;
        font-family: "Roboto Light", Helvetica, sans-serif;
        font-size: 14px;
      }

      .card {
        border: none;

        h5,
        h5 a {
          color: $grey-text;
          font-family: "Roboto Slab Light", Georgia, serif;

          .badge.badge-blue {
            font-family: "Roboto Light", Helvetica, sans-serif;
            background-color: $aon-blue;
            color: $white;
          }
        }

        h5 a {
          &:hover {
            color: lighten($grey-text, 20%);
            text-decoration: none;
          }
        }

        .list-group {
          img {
            height: 50px;
            width: 50px;
          }

          .list-group-item {
            padding: 10px 0px;
            border: none;

            &:hover,
            &:active {
              bborder: none;
              background-color: $white;
            }

            p {
              margin-bottom: 0px;
              line-height: inherit;
              word-break: normal;
              // font-weight: 300;
              font-family: "Roboto Regular", Helvetica, sans-serif;
              font-size: 14px;
            }
          }
        }

        .card-empty {
          text-align: center;

          img {
            width: 50%;
            margin: 10px 10px 16px 10px;
          }

          p {
            font-family: "Roboto Regular", Helvetica, sans-serif;
            font-size: 13px;
            color: $grey-mid-dark;
            margin: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .jumbotron.dashboard {
    .container {
      .row {
        .prompt {
          .btn {
            width: 165px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .dashboard {
    .getting-started {
      .card-text {
        margin-top: 30px;
      }
      .card-footer {
        margin-left: 0px;
      }
    }
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .learning-plan-summary {
    .container {
      .card {
        h5 {
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .dashboard {
    .getting-started {
      img {
        width: 100%;
        display: block;
        margin-right: 0px;
      }
      .card-title {
        width: 100%;
        display: inline-block;
        margin-right: 0px;
        margin-top: 20px;
      }
      .card-text {
        margin-top: 00px;
      }
      .card-footer {
        margin-left: 0px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .dashboard {
    .whats-new,
    .learning-plan-summary {
      .card-deck-wrapper {
        margin-right: 0;
        margin-left: 0;

        .card-deck {
          display: block;
          width: 100%;
          margin-bottom: 0.75rem;
          table-layout: fixed;
          border-spacing: 1.25rem 0;
          margin: 0px;

          .card {
            display: block;
            margin: 20px 0px;
            vertical-align: top;
          }
        }
      }
    }

    .learning-plan-summary {
      .container {
        padding: 20px !important;
      }
    }
    .getting-started {
      .container {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .jumbotron.dashboard {
    .container {
      .row {
        .prompt {
          .btn {
            width: 100%;
            margin: 6px 0px;
          }
        }
      }
    }
  }

  .dashboard {
    .whats-new {
      margin: 0px 20px;

      @media screen and (max-width: 575px) {
        margin: 0px;
        padding: 0px 20px;
      }
    }
  }
}
