// SWITCHES.SCSS

.active-all-switch {
  border: 1px solid $grey-light;
  color: $grey-mid-light;
  border-radius: 20px;
  // height: 20px;
  padding: 0px 10px 0px 10px;
  // display: inline;
  // width: 200px;
  display: inline-block;
  float: right;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 23px;
  font-family: "Roboto Medium", Helvetica, sans-serif;

  .all-label.selected,
  .active-label.selected {
    color: $grey-text;
    font-family: "Roboto Bold", Helvetica, sans-serif;
    // font-weight: bold;
  }
}

.goal-date {
  width: 100px;
  font-size: 12px;
  height: 26px;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: $grey-light;
  color: $grey-text;
  display: inline;
  line-height: 20px;
  margin-top: 0px;
}

.datepicker table tr td.today {
  background-color: lighten($aon-yellow, 10%);

  &:hover {
    background-color: $aon-yellow;
  }
}

.status-badge {
  display: inline-block;

  &:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 2px;
    padding-top: 10px;
    border-radius: 1px;
    margin-bottom: -1px;
  }

  &.not-started:before {
    background: $bootstrap-grey;
  }
  &.in-progress:before {
    background: $aon-yellow;
  }
  &.completed:before {
    background: $aon-green;
  }
}
