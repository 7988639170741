// COURSE.SCSS

.course {
  padding: 0px;

  @media screen and (max-width: 575px) {
    padding: 0px 15px;
  }

  .container {
    background-color: $white;
    padding: 20px;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

    h2 {
      font-family: "Roboto Slab Regular", Georgia, serif;
      font-size: 28px;
    }

    .content-details {
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: $grey-mid-light;
    }

    .quick-links {
      margin-top: 10px;
      display: inline-flex;
      align-items: center;

      @media screen and (max-width: 767px) {
        position: relative;
        padding: 0px 0px 0px 0px;
        display: block;
      }

      a,
      .add-to-playlist {
        font-size: 12px;
        color: $grey-text;
        background-color: $grey-light;
        padding: 4px 6px;
        border-radius: 4px;

        &:hover {
          text-decoration: none;
          color: lighten($grey-text, 20%);
          cursor: pointer;
        }
      }
    }

    .description {
      font-family: "Roboto Regular", Helvetica, sans-serif;
      font-size: 14px;
      word-break: break-word;
    }

    .course-content {
      box-shadow: none;
      font-family: "Roboto Regular", Helvetica, sans-serif;
      font-size: 14px;

      .list-group {
        .list-group-item {
          padding: 20px 0px;
          border: none;

          &:hover,
          &:active {
            background-color: $white;
          }

          img {
            height: 80px;
            width: 80px;
            min-width: 80px;
            object-fit: cover;
          }

          h3 {
            font-family: "Roboto Slab Regular", Georgia, serif;
            font-size: 20px;

            a {
              color: $grey-text;
              word-break: normal;

              &:hover {
                color: lighten($grey-text, 20%);
                text-decoration: none;
              }
            }
          }

          .content-details {
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            color: $grey-mid-light;
          }

          p {
            margin-bottom: 0px;
            line-height: 20px;
            word-break: normal;
          }

          .quick-links {
            margin-top: 10px;
            display: inline-flex;
            align-items: center;

            a {
              font-size: 14px;
              font-weight: bold;
              text-transform: uppercase;
              color: $grey-mid-light;
              padding: 4px 6px;

              &:hover {
                text-decoration: none;
                color: lighten($grey-mid-light, 10%);
              }
            }
          }

          .btn-explore,
          .btn-show,
          .btn-launch,
          .btn-add {
            width: 116px;
          }
          .btn-add {
            width: 160px;
          }
        }
      }

      .course-image {
        width: 100%;
        height: 260px;
        object-fit: cover;
      }
    }

    .table {
      font-size: 14px;

      .thead-light th {
        color: $grey-text;
        background-color: $grey-bg;
        border-color: $grey-bg;
        vertical-align: middle;
        font-weight: 600;
      }

      &.table-hover {
        tbody tr:hover td {
          background-color: lighten($aon-blue, 30%);
        }
      }

      td {
        border-top: 1px solid $grey-bg;
      }

      .sortable {
        cursor: pointer;
      }
    }
  }
}
