// COMMUNITY.SCSS

.community {
  background-color: $white;
  padding: 20px;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

  .image {
    //background-image: url("/static/img/demo/helpUsGrow.jpg");
    background-color: $grey-bg;
    background-size: cover;
    min-height: 200px;
    background-repeat: no-repeat;
    background-position: top -60px center;
  }

  .text {
    h2 {
      font-family: "Roboto Slab Light", Georgia, serif;
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .community {
    .image {
      background-position: top -10px center;
    }
  }
}

@media screen and (max-width: 767px) {
  .community {
    .image {
      background-position: top 0px center;
    }
  }
}
