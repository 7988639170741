// JUMP_START_LEARNING.SCSS

.jump-start-learning {
  background-color: $white;
  padding: 20px;
  box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.16);

  .image {
    //background-image: url('/static/img/jumpStart-primary.jpg');
    background-color: $grey-bg;
    background-size: cover;

    // img {
    //   height: 100%;
    // }
  }

  .activity {
    h2 {
      font-family: 'Roboto Slab Light', Georgia, serif;
    }

    .dropdown {
      margin-bottom: 40px;

      .jsl-title {
        font-family: 'Roboto Slab Light', Georgia, serif;
        text-align: left;
        color: $grey-text;
      }

      .btn.jsl-title-btn {
        text-align: left;
        pointer-events:none;
        background-color: lighten($aon-blue, 20%);
        opacity: 1;

        &:hover {
          pointer-events:none;
        }
      }

      .dropdown-toggle {
        text-align: center;
        color: $grey-text;

        svg {
          margin-left: 2px;
        }

        &:after {
          display: none;
        }
      }

      .dropdown-menu {
        &.show {
          width: 100%;
          z-index: 1;

          :focus {
            background-color: $white;
            color: $grey-mid-light;
          }
        }
      }
    }

    .list-group {
      .list-group-item {
        padding: 10px 0px;
        border: none;

        &:hover,
        &:active {
          bborder: none;
          background-color: $white;
        }

        img {
          height: 80px;
          width: 80px;
          min-width: 80px;
          object-fit: cover;
        }

        h3 {
          font-size: 22px;
          margin-bottom: 0px;
          font-family: 'Roboto Slab Light', Georgia, serif;

          a {
            color: $grey-text;
            word-break: normal;

            &:hover {
              color: lighten($grey-text, 20%);
              text-decoration: none;
            }
          }
        }

        p {
          margin-bottom: 0px;
          line-height: 20px;
          word-break: normal;
        }

        .content-details {
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          color: $grey-mid-light;
        }

        .quick-links {
          margin-top: 4px;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          color: $grey-mid-light;

          a,
          .add-to-playlist {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            color: $aon-teal;
            cursor: pointer;

            &:hover {
              text-decoration: none;
              color: lighten($aon-teal, 5%);
            }
          }
        }

        .btn-launch {
          width: 126px;
        }
      }
    }

    hr {
      margin: 10px 0px;
    }

    .dropdown-item.active {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      background-color: lighten($aon-blue, 10%);
    }
    .dropdown-item:hover {
      background-color: $white;
      color: $grey-mid-light;
      cursor: pointer;
    }

    .jsl-toggle {
      display: none;

      &.jsl-set-one {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .jump-start-learning .activity h2 {
    font-size: 27px;
  }
  .jump-start-learning .activity .dropdown .jsl-title {
    font-size: 17px;
  }
}


@media screen and (max-width: 991px) {
  .jump-start-learning {
    .image {
      position: relative;
      height:300px;
      width: 100%;
      overflow: hidden;
      background-position: top -460px center;
      background-repeat: no-repeat;
    }
  }
}

@media screen and (max-width: 767px) {
  .jump-start-learning {

    .image {
      background-position: top -360px center;
    }

    .dropdown .dropdown-item {
      white-space: normal;
    }
    .activity {
      .list-group {
        .list-group-item {
          img {
            float: left;
          }

          .w-100 {
            padding-left: 116px;
          }

          .btn-launch {
            width: 100%;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 525px) {
  .jump-start-learning {

    .image {
      background-position: top -260px center;
    }
  }
}
