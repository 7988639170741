// YAMMER.SCSS

.yammer {
  background-color: $white;
  padding: 20px;
  // box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

  .text {
    h2 {
      font-family: "Roboto Slab Light", Georgia, serif;
      font-size: 28px;
    }
  }
}
